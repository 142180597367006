import AcmeLogo from "../assets/images/partner_logos/acme-logo.png";
import { ReactComponent as HealthVanaLogo } from "../assets/images/partner_logos/HealthVana-logo.svg";
import { ReactComponent as CentivoLogo } from "../assets/images/partner_logos/centivo.svg";
import CovetLogo from "../assets/images/partner_logos/covet-logo.png";
import SanaLogo from "../assets/images/partner_logos/sana-logo.png";
import KochLogo from "../assets/images/partner_logos/Koch.png";
import { ReactComponent as MarpaiLogo } from "../assets/images/partner_logos/marpai-logo.svg";
import ThomasJeffersonUniversityURL from "../assets/images/partner_logos/thomas_jefferson_university_logo.png";
import { ReactComponent as NeuraLogo } from "../assets/images/partner_logos/neura_logo.svg";
import { ReactComponent as TrioLogo } from "../assets/images/partner_logos/trio_logo.svg";
import EmoryLogoURL from "../assets/images/partner_logos/emory_logo.png";
import { ReactComponent as RiverLogo } from "../assets/images/partner_logos/river_logo.svg";
import TheranicaLogoUrl from "../assets/images/partner_logos/theranica-logo.png";
import NomiLogoUrl from "../assets/images/partner_logos/nomi.png";

export const partnersName = {
  // key should be write by uppercase.
  NEURA_SLEEP: "neura_sleep",
  TEST_INTERNAL: "test_internal",
  HEALTHVANA: "healthvana",
  COVET: "covet",
  SANA: "sana",
  MARPAI: "marpai",
  CENTIVO: "centivo",
  JEFFERSON: "jefferson",
  CBTI: "cbti",
  OMNI: "omni",
  EMORY: "emory",
  RIVER_HEALTH: "riverhealth",
  KOCH: "koch",
  THERANICA: "theranica",
  CTG: "ctg",
  DAVIS_BOXING_PROMOTIONS: "davisboxingpromotions",
  PANGEA_ILLINOIS: "pangeatest",
  PANGEA_RAPIDREFERRAL: "rapidreferral",
  SPORTS: "sports",
  ATHLETES: "athletes",
  PERSONAL_INJURY: "personalinjury",
  CLINICS: "clinics",
  NOMI_HEALTH: "nomihealth",
  BAY_AREA_NEURO_DX: "bayareaneurodx",
} as const;

export const partnersLabel = {
  NEURA_SLEEP: "Neura Sleep",
  TEST_INTERNAL: "Test Internal",
  HEALTHVANA: "Healthvana",
  COVET: "Covet",
  SANA: "Sana",
  MARPAI: "Marpai",
  CENTIVO: "Centivo",
  JEFFERSON: "Jefferson",
  CBTI: "CBT-I",
  OMNI: "Trio",
  EMORY: "Emory",
  RIVER_HEALTH: "River Health",
  KOCH: "Koch Industries",
  THERANICA: "Theranica",
  CTG: "Concussion Therapy Group",
  DAVIS_BOXING_PROMOTIONS: "Davis Boxing Promotions",
  PANGEA_ILLINOIS: "Pangea Illinois",
  PANGEA_RAPIDREFERRAL: "Pangea RapidReferral",
  SPORTS: "Sports",
  ATHLETES: "Athletes",
  PERSONAL_INJURY: "Personal Injury",
  CLINICS: "Clinics",
  NOMI_HEALTH: "Nomi Health",
  BAY_AREA_NEURO_DX: "Bay Area Neuro Dx",
} as const;

interface ILogo {
  partner: string;
  logo: React.ReactNode;
}

// The logo in the array objects below depends on the extension file.
// svg: is using as a ReactComponent <Logo />
// png: must be use as a url for the img tag (e.g. <img src={logoImage} alt={partnerName} />)
export const partnerList: ILogo[] = [
  {
    partner: partnersName.TEST_INTERNAL,
    logo: <img src={AcmeLogo} alt={partnersName.TEST_INTERNAL} />,
  },
  {
    partner: partnersName.HEALTHVANA,
    logo: <HealthVanaLogo />,
  },
  {
    partner: partnersName.COVET,
    logo: <img src={CovetLogo} alt={partnersName.COVET} />,
  },
  {
    partner: partnersName.SANA,
    logo: <img src={SanaLogo} alt={partnersName.SANA} />,
  },
  {
    partner: partnersName.MARPAI,
    logo: <MarpaiLogo />,
  },
  {
    partner: partnersName.CENTIVO,
    logo: <CentivoLogo />,
  },
  {
    partner: partnersName.NEURA_SLEEP,
    logo: <NeuraLogo />,
  },
  {
    partner: partnersName.JEFFERSON,
    logo: <img src={ThomasJeffersonUniversityURL} alt={partnersName.JEFFERSON} />,
  },
  {
    partner: partnersName.CBTI,
    logo: <NeuraLogo />,
  },
  {
    partner: partnersName.OMNI,
    logo: <TrioLogo />,
  },
  {
    partner: partnersName.EMORY,
    logo: <img src={EmoryLogoURL} alt={partnersName.EMORY} />,
  },
  {
    partner: partnersName.RIVER_HEALTH,
    logo: <RiverLogo />,
  },
  {
    partner: partnersName.KOCH,
    logo: <img src={KochLogo} alt={partnersName.KOCH} />,
  },
  {
    partner: partnersName.THERANICA,
    logo: <img src={TheranicaLogoUrl} alt={partnersName.THERANICA} />,
  },
  {
    partner: partnersName.CTG,
    logo: <NeuraLogo />,
  },
  {
    partner: partnersName.DAVIS_BOXING_PROMOTIONS,
    logo: <NeuraLogo />,
  },
  {
    partner: partnersName.PANGEA_ILLINOIS,
    logo: <NeuraLogo />,
  },
  {
    partner: partnersName.PANGEA_RAPIDREFERRAL,
    logo: <NeuraLogo />,
  },
  {
    partner: partnersName.SPORTS,
    logo: <NeuraLogo />,
  },
  {
    partner: partnersName.ATHLETES,
    logo: <NeuraLogo />,
  },
  {
    partner: partnersName.PERSONAL_INJURY,
    logo: <NeuraLogo />,
  },
  {
    partner: partnersName.CLINICS,
    logo: <NeuraLogo />,
  },
  {
    partner: partnersName.NOMI_HEALTH,
    logo: <img src={NomiLogoUrl} alt={partnersName.NOMI_HEALTH} />,
  },
  {
    partner: partnersName.BAY_AREA_NEURO_DX,
    logo: <NeuraLogo />,
  },
];
