import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import OnboardingFormHeading from "../../components/OnboardingForm/OnboardingFormComponents/OnboardingFormHeading/OnboardingFormHeading";
import { Field, Form, Formik } from "formik";
import styles from "./CheckInsurancePage.module.scss";
import Button from "../../components/Button/Button";
import OnboardingFormSelect from "../../components/OnboardingForm/OnboardingFormComponents/OnboardingFormSelect/OnboardingFormSelect";
import { useMutation, useQuery } from "@tanstack/react-query";
import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";
import { useState } from "react";
import { statesArray } from "../../helpers/StatesArray";
import { conditionFocusTitles } from "../../utils/conditionFocus";
import { ReactComponent as IconSpin } from "../../assets/icons/spinner.svg";
import * as yup from "yup";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import ModalLayout from "../../components/ModalLayout/ModalLayout";
import Drawer from "react-modern-drawer";
import { ReactComponent as Cancel } from "../../assets/images/svg/cancel.svg";

type CheckInsuranceForm = {
  payorID: string;
  patientState: string;
  patientConditionFocusItem: string;
};

const checkInsuranceSchema = yup.object({
  payorID: yup.string(),
  patientState: yup.string().required("State is required"),
  patientConditionFocusItem: yup.string().required("Condition is required"),
});

const EMPTY_KEY = "none";
const OTHER_KEY = "other";

export const CheckInsurancePage = () => {
  const api = new FirebaseApi();
  const firestore = api.firebaseFirestore();
  const functions = api.firebaseFunctions();
  const [patientState, setPatientState] = useState<string>("");
  const [modalVisible, setModalVisible] = useState(false);
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  const { data: payors = [] } = useQuery({
    queryKey: ["payors"],
    queryFn: async () => {
      const insurances = await firestore.getInsuranceLaunchControls();
      const payors = Object.entries(insurances.livePayors)
        .map(([value, label]) => ({
          label,
          value,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      return payors;
    },
  });

  const { data: payorIDs = [] } = useQuery({
    queryKey: ["payorIDs", patientState],
    queryFn: async () => {
      if (!patientState) return [];
      const res = await functions.innPayorsInStatePublic({ patientState });
      return res;
    },
  });

  const {
    mutate: checkInsurance,
    isLoading,
    data: response,
  } = useMutation({
    mutationKey: ["check-insurance"],
    mutationFn: (data: CheckInsuranceForm) =>
      functions.checkRxAndInsurancePublic({
        ...data,
        payorID: data.payorID === EMPTY_KEY || data.payorID === OTHER_KEY ? null : data.payorID,
      }),
    onSuccess: () => {
      setModalVisible(true);
    },
  });

  const handleStateChange = (value: string) => {
    setPatientState(value ? value.trim() : "");
  };

  const handeSubmit = async (values: CheckInsuranceForm) => {
    checkInsurance(values);
  };

  const conditions = Object.entries(conditionFocusTitles).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  const payorsOptions = [
    { label: "None", value: EMPTY_KEY },
    ...payors.filter((payor) => payorIDs.includes(payor.value)),
    { label: "Other / Not Listed", value: OTHER_KEY },
  ];

  const handleClose = () => {
    setModalVisible(false);
  };

  const modalContent = (payorID: string) => (
    <div className={styles.response}>
      <span>
        Based on your answers, in {statesArray.find((st) => st.value === patientState)?.label}:
      </span>
      {response?.canPrescribe ? (
        <span className={styles.yes}>
          We <b>can prescribe</b> medication for your condition.
        </span>
      ) : (
        <span className={styles.no}>
          We <b>cannot prescribe</b> medication for your condition.
        </span>
      )}
      {response?.canAcceptInsurance ? (
        <>
          <span className={styles.yes}>
            Your insurance appears to be in-network. We will need to confirm the exact details of
            your plan to verify your eligibility.
          </span>
          <span className={styles.yes}>
            Please send a photo of the front and back of your insurance card along with your date of
            birth to{" "}
            <a className={styles.link} href="mailto:support@neurahealth.co">
              support@neurahealth.co
            </a>{" "}
            or by text to 786-741-8133 and someone will be in touch to confirm your in-network
            status.
          </span>
        </>
      ) : response?.canAcceptInsurance === false || payorID === OTHER_KEY ? (
        <span className={styles.no}>We don't accept insurance for your condition.</span>
      ) : undefined}
      <Button className={styles.formButton} shape="squared" onClick={handleClose}>
        Close
      </Button>
    </div>
  );

  return (
    <OnboardingLayout visibleLogo>
      <div className={styles.page}>
        <OnboardingFormHeading title="Insurance and Prescription" />
        <h2 className={styles.description}>
          Use this to see if we can prescribe medication or accept your insurance for your condition
        </h2>
        <Formik
          initialValues={{ payorID: "", patientState: "", patientConditionFocusItem: "" }}
          validationSchema={checkInsuranceSchema}
          onSubmit={handeSubmit}
        >
          {({ errors, touched, isSubmitting, setFieldValue, values }) => (
            <Form className={styles.insuranceForm}>
              <Field
                onValueChange={(value: string) => {
                  setFieldValue("payorID", undefined);
                  handleStateChange(value);
                }}
                name="patientState"
                label="State"
                component={OnboardingFormSelect}
                options={statesArray}
                error={errors.patientState && touched.patientState}
                validateOnChange
              />
              <Field
                label="Primary Condition / Area of Concern"
                name="patientConditionFocusItem"
                component={OnboardingFormSelect}
                options={conditions}
                error={errors.patientConditionFocusItem && touched.patientConditionFocusItem}
                disabled={!patientState}
                validateOnChange
              />
              <Field
                name="payorID"
                label="Insurance Carrier (optional)"
                component={OnboardingFormSelect}
                options={payorsOptions}
                error={errors.payorID && touched.payorID}
                disabled={!patientState}
                validateOnChange
              />
              {response && !isLoading && modalVisible ? (
                isResponsiveView ? (
                  <Drawer
                    open={!!modalVisible}
                    onClose={handleClose}
                    direction="bottom"
                    className={styles.drawer}
                  >
                    <button className={styles.close} onClick={handleClose}>
                      <Cancel />
                    </button>
                    {modalContent(values.payorID)}
                  </Drawer>
                ) : (
                  <ModalLayout handleClose={handleClose}>
                    {modalContent(values.payorID)}
                  </ModalLayout>
                )
              ) : null}
              <Button
                className={styles.formButton}
                type="submit"
                disabled={isSubmitting}
                shape="squared"
              >
                {isLoading ? <IconSpin /> : "Submit"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </OnboardingLayout>
  );
};
