import * as Yup from "yup";
import { emailSchema } from "./common";
export const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      "Password must contain at least one uppercase and lowercase letters."
    )
    .matches(/^(?=.*[0-9])/, "Password must contain at lease one number."),
});

export const RegisterSchema = Yup.object().shape({
  email: emailSchema,
});
