import { useEffect, useRef, useState } from "react";

import styles from "./BookVisitModal.module.scss";

import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";
import firebase from "firebase/compat/app";

import BookCard from "../BookCard/BookCard";
import { Select } from "../Select/Select";
import { Tabs, ITab } from "../Tabs/Tabs";

import {
  getBookingURI,
  bookingProviderType,
  bookingPatientType,
} from "../../helpers/getBookingURI";
import { conditionFocusTitles } from "../../utils/conditionFocus";
import { IProvider } from "../../types/provider";
import { IPatient } from "../../types/patient";
import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";
interface IBookProps {
  uid: string;
  patientData: IPatient;
  partnerData: any;
  updatePayment: (url: string) => void;
}
interface ICareTeamGroup {
  title: string;
  list: string[];
}
interface IOption {
  value: string;
  label: string;
}

const tabs: ITab[] = [
  { title: "Providers", id: 1 },
  { title: "Coaches", id: 2 },
];

export const BookVisitModal = ({
  uid: patientID,
  patientData,
  partnerData,
  updatePayment,
}: IBookProps) => {
  const [careTeam, setCareTeam] = useState<IProvider[]>([]);
  const [activeTab, setActiveTab] = useState(1);
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [specialtySelect, setSpecialitySelect] = useState<string>("");
  const [currentLink, setCurrentLink] = useState<string>("");

  const selectRef = useRef(null);

  let firestore = firebase.firestore;

  const [careTeamGroups, setCareTeamGroups] = useState<ICareTeamGroup[]>([]);

  useEffect(() => {
    if (!patientData.conditionFocus) return;
    const opt = patientData.conditionFocus.map((condition) => ({
      label: conditionFocusTitles[condition],
      value: condition,
    }));
    setOptions(opt);
    setSpecialitySelect(patientData.conditionFocus[0]);
  }, [patientData.conditionFocus]);

  useEffect(() => {
    if (!specialtySelect) return;
    setCareTeamGroups([]);

    setLoader(true);

    const batchFetchProviders = async (ctmIDs: string[]) => {
      const batchSize = 10;
      const providerDocs = [];
      for (let i = 0; i < ctmIDs.length; i += batchSize) {
        const chunk = ctmIDs.slice(i, i + batchSize);
        const providerQS = await firestore()
          .collection("care_team")
          .where(firestore.FieldPath.documentId(), "in", chunk)
          .get();
        providerDocs.push(...providerQS.docs);
      }
      return providerDocs;
    };

    firebase
      .functions()
      .httpsCallable(activeTab === 2 ? "getAvailableCoaches" : "getAvailableProvidersV3")({
        patientID,
        conditionFocus: specialtySelect,
      })
      .then((response) => {
        const responseData = response.data;
        const slotCounts = responseData.slotCounts;
        const unsortedCTMIDs = Object.keys(slotCounts);
        const ctmIDs = unsortedCTMIDs.sort((a, b) => {
          return slotCounts[a] < slotCounts[b] ? 1 : -1;
        });

        batchFetchProviders(ctmIDs)
          .then((providerDocs) => {
            let careTeamArray: any[] = [];
            let careTeamMap: any = {};

            // Arrange docs in the same order as the IDs
            for (const providerDoc of providerDocs) {
              careTeamMap[providerDoc.id] = providerDoc;
            }

            for (const ctmID of ctmIDs) {
              careTeamArray.push({
                ...careTeamMap[ctmID].data(),
                ...{ careTeamMemberID: ctmID, slotCount: slotCounts[ctmID] },
              });
            }

            const teamMemberImageURLPromises = careTeamArray.map((careTeamMember) =>
              firebase
                .storage()
                .refFromURL("gs://gezunt-c3bfe.appspot.com/doctors/" + careTeamMember.imageFileName)
                .getDownloadURL()
            );
            Promise.all(teamMemberImageURLPromises).then((teamMemberImageURLs) => {
              let finalTeamMemberArray = [];
              let teamMemberLen = teamMemberImageURLs.length;
              for (let i = 0; i < teamMemberLen; i++) {
                finalTeamMemberArray.push({
                  ...careTeamArray[i],
                  ...{ careTeamMemberImageURL: teamMemberImageURLs[i] },
                });
              }
              setCareTeam(finalTeamMemberArray);
              setCareTeamGroups(responseData.data);
              setCareTeam(finalTeamMemberArray);
            });
          })
          .then(() => setLoader(false));
      });
  }, [firestore, patientID, patientData, activeTab, specialtySelect]);

  const bookTheVisit = async (provider: bookingProviderType) => {
    const functions = new FirebaseApi().firebaseFunctions();
    if (partnerData?.showCreditCardUIBeforeBooking) {
      setCurrentLink(provider.acuityAppointmentLink);
      const { paymentMethodSet, sessionURL } = await functions.checkPaymentAndCreatePortalSession(
        patientID
      );

      if (!paymentMethodSet && sessionURL) {
        updatePayment(sessionURL);
        setCurrentLink("");
        return;
      }
    }

    const patient: bookingPatientType = {
      firstName: patientData.firstName,
      lastName: patientData.lastName,
      email: patientData.email,
      phoneNumber: patientData.phoneNumber,
    };
    setCurrentLink("");
    window.open(getBookingURI(provider, patient), "_blank");
  };

  const renderTabView = () => (
    <div className={styles.doctors}>
      {loader ? (
        <div className={styles.loader}>
          <LoaderSpinner />
        </div>
      ) : (
        careTeamGroups.map((careTeamGroup: ICareTeamGroup) => (
          <div key={`ctmGroupSection${Math.random()}`}>
            <p className={styles.title}>{careTeamGroup.title}</p>
            {careTeam.map((careTeamMember) =>
              careTeamMember.careTeamMemberID &&
              careTeamGroup.list.includes(careTeamMember.careTeamMemberID) ? (
                <BookCard
                  key={careTeamMember.careTeamMemberID}
                  careTeamMember={careTeamMember}
                  currentLink={currentLink}
                  onClick={() => {
                    const { acuityAppointmentLink } = careTeamMember;
                    bookTheVisit({ acuityAppointmentLink });
                  }}
                />
              ) : null
            )}
          </div>
        ))
      )}
    </div>
  );

  return (
    <div className={styles.book}>
      <div className={styles.bookTitle}>Book a visit</div>

      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}>
        {activeTab === 1 && (
          <div style={{ marginTop: "30px" }}>
            {options.length > 1 && (
              <>
                <div className={styles.selectContainer}>
                  <p className={styles.speciality}>Speciality:</p>
                  <Select
                    ref={selectRef}
                    options={options}
                    selectedProvider={specialtySelect}
                    onSelected={(value) => {
                      setSpecialitySelect(value);
                    }}
                    name="selectCondition"
                  />
                </div>
                <div className={styles.line}></div>
                <div style={{ marginBottom: "38px" }}></div>
              </>
            )}
            {renderTabView()}
          </div>
        )}
        <div style={{ marginTop: "30px" }}>{activeTab === 2 && renderTabView()}</div>
      </Tabs>
    </div>
  );
};
