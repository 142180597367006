import { Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Link, useHistory, useLocation } from "react-router-dom";
import amplitude from "amplitude-js";
import OnboardingFormDescription from "../OnboardingFormComponents/OnboardingFormDescription/OnboardingFormDescription";
import Grid, { GridElem } from "../../Grid/Grid";
import OnboardingFormTextInput from "../OnboardingFormComponents/OnboardingFormTextInput/OnboardingFormTextInput";
import OnboardingFormSubmit from "../OnboardingFormComponents/OnboardingFormSubmit/OnboardingFormSubmit";
import { IFormData } from "../OnboardingFormSection.interface";
import { ReactComponent as LoginIconBackground } from "../../../assets/icons/onboarding-login-background-icon.svg";
import breakpoints from "../../../assets/styles/variables/breakpoints.module.scss";

import FirebaseApi from "../../../api/FirebaseApi/FirebaseApi";

import styles from "./OnboardingFormSectionLogin.module.scss";
import cn from "classnames";
import { ReactComponent as IconHeader } from "../../../assets/icons/onboarding-login-header.svg";
import { useMediaQuery } from "react-responsive";
import qs from "query-string";
import { isNotExcludedFromTracking } from "../../../helpers/isNotExcludedFromTracking";
import { emailSchema } from "../../../schemas/common";

interface IOnboardingFormSectionLogin {
  formData?: IFormData;
  setFormData: (formData: any) => void;
}

type FormValuesT = {
  email: string;
  password: string;
};

type queryType = { email: string };

const OnboardingFormSectionLogin = (props: IOnboardingFormSectionLogin) => {
  const { formData, setFormData } = props;

  const { state } = useLocation();
  const history = useHistory();
  const params = useLocation();
  const query = qs.parse(params.search) as queryType;

  const functions = new FirebaseApi().firebaseFunctions();
  const firestore = new FirebaseApi().firebaseFirestore();

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  const onSubmit = async (values: FormValuesT, formikHelpers: FormikHelpers<FormValuesT>) => {
    try {
      formikHelpers.setSubmitting(true);
      const firebaseAuth = new FirebaseApi().auth();

      const userCredential = await firebaseAuth.logIn(values.email, values.password);

      setFormData({ ...formData, uid: userCredential?.user?.uid });
      if (userCredential?.user?.uid) {
        amplitude.getInstance().setUserId(userCredential.user.uid);
        const emailIdentify = new amplitude.Identify().set("email", values?.email);
        amplitude.getInstance().identify(emailIdentify);
        amplitude.getInstance().logEvent("onboarding_v2_existing_account_login_click");

        await firestore.updatePatient({
          data: {
            uid: userCredential.user.uid,
            defaultPasswordUpdated: true,
          },
        });

        let d = new Date();
        d.setUTCHours(0, 0, 0, 0);
        functions.updateHubspotContact("app_login", {
          email: values.email,
          eventValue: d.toISOString(),
        });

        functions.updateHubspotContact("default_password_updated", {
          email: values.email,
          eventValue: true,
        });

        isNotExcludedFromTracking(values.email, () => {
          // @ts-ignore
          window?.dataLayer?.push({
            user_id: userCredential?.user?.uid,
          });

          // @ts-ignore
          window?.dataLayer.push({
            event: "onboarding_v2_existing_account_login_click",
          });
        });

        formikHelpers.setSubmitting(false);
        setFormData({ ...formData, uid: userCredential?.user?.uid });
      }
      formikHelpers.setSubmitting(false);
      history.push({ pathname: "/onboarding", state });
    } catch (error) {
      formikHelpers.setSubmitting(false);

      // @ts-ignore
      return formikHelpers.setFieldError("password", "Invalid email or password.");
    }
  };

  return (
    <Formik
      initialValues={{
        email: (query.email && decodeURIComponent(query.email)) ?? "",
        password: "",
      }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        email: emailSchema,
        password: Yup.string().required("Password is required."),
      })}
      validateOnChange
    >
      {({ isValid, isSubmitting }) => {
        return (
          <Form className="d-flex flex-column mh-90" id="email_password_login">
            {isResponsiveView && <LoginIconBackground className={styles.loginBackgroundIcon} />}

            <div className={cn(styles.headerWrapper, "d-flex justify-content-center")}>
              <div className="d-flex flex-column w-100">
                <h4 className={styles.header}>Welcome back</h4>
              </div>

              <IconHeader className={styles.iconHeader} />
            </div>

            <Grid rowGap={0}>
              <GridElem>
                <Field
                  label="Enter an email"
                  name="email"
                  type="email"
                  component={OnboardingFormTextInput}
                />
              </GridElem>

              <GridElem>
                <Field
                  label="Password"
                  name="password"
                  type="password"
                  see
                  component={OnboardingFormTextInput}
                />
              </GridElem>

              <GridElem>
                <OnboardingFormDescription
                  className={styles.logInText}
                  text={
                    <Link
                      className={styles.link}
                      to={{
                        pathname: "/reset-password",
                        search: window.location.search,
                      }}
                      onClick={(event) => isSubmitting && event.preventDefault()}
                    >
                      Forgot your password?
                    </Link>
                  }
                />
              </GridElem>
            </Grid>

            <div className="d-flex justify-content-center mt-auto">
              <OnboardingFormSubmit
                title="Continue"
                disabled={!isValid}
                isSubmitting={isSubmitting}
              />
            </div>

            <OnboardingFormDescription
              className={styles.logInText}
              text={
                <span>
                  New to Neura Health?{" "}
                  <Link
                    className={styles.link}
                    to={{
                      pathname: "/onboarding",
                      search: window.location.search,
                    }}
                    onClick={(event) => isSubmitting && event.preventDefault()}
                  >
                    Sign Up
                  </Link>
                </span>
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default OnboardingFormSectionLogin;
