import React, { useState } from "react";
import Select, {
  components,
  ControlProps,
  SingleValueProps,
  ValueContainerProps,
} from "react-select";
import cn from "classnames";
import OnboardingFormError from "../OnboardingFormError/OnboardingFormError";

import styles from "./OnboardingFormSelect.module.scss";

interface IOnboardingFormSelect {
  field: any;
  form: any;
  label?: string;
  setFormValues?: (value: any) => void;
  options: Array<{ label: string; value: string }>;
  onValueChange?: (value: string) => void;
  className?: string;
  disabled?: boolean;
}

const Control = ({ children, ...props }: ControlProps<any, any>) => {
  return (
    <components.Control {...props} className={styles.Control}>
      <label className={styles.Placeholder} htmlFor="formSelect">
        {props.selectProps["aria-label"]}
      </label>
      {children}
    </components.Control>
  );
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<any, any>) => {
  return (
    <components.ValueContainer {...props} className={styles.ValueContainer}>
      {children}
    </components.ValueContainer>
  );
};

const SingleValue = ({ children, ...props }: SingleValueProps<any, any>) => {
  return (
    <components.SingleValue {...props} className={styles.SingleValue}>
      {children}
    </components.SingleValue>
  );
};

const Menu = ({ children, ...props }: any) => {
  return (
    <components.Menu {...props} className={styles.Menu}>
      {children}
    </components.Menu>
  );
};

const OnboardingFormSelect = ({
  field,
  form,
  options,
  setFormValues,
  onValueChange,
  label,
  className,
  disabled,
}: IOnboardingFormSelect) => {
  const [focus, setFocus] = useState(!!field?.value?.value);

  return (
    <div className={cn(styles.wrapper, className)}>
      <Select
        // this forces the component to rerender when the value changes
        key={JSON.stringify(field.value)}
        data-testid="stateForCare"
        controlShouldRenderValue
        name={field.name}
        inputId="formSelect"
        aria-label={label}
        components={{
          Control,
          ValueContainer,
          SingleValue,
          Menu,
          Placeholder: () => null,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        defaultInputValue={form?.values?.stateForCare}
        isClearable
        isSearchable
        options={options}
        onChange={(e) => {
          //@ts-expect-error value exists in e
          onValueChange?.(e?.value);
          form.getFieldHelpers(field.name).setTouched();
          //@ts-expect-error value exists in e
          form.setFieldValue(field.name, e?.value);
          //@ts-expect-error value exists in e
          setFormValues?.((prevState: any) => ({ ...prevState, stateForCare: e?.value }));
        }}
        className={cn(
          styles.element,
          focus && styles.isFocus,
          field.value !== "" && !form.errors[field.name] && styles.isValid,
          form.touched[field.name] && form.errors[field.name] && styles.isAlert
        )}
        onMenuOpen={() => setFocus(true)}
        isDisabled={disabled}
        value={options.find((option) => option.value === field.value)}
      />

      {form.touched[field.name] && form.errors[field.name] && (
        <OnboardingFormError errors={form.errors} inputName={field.name} />
      )}
    </div>
  );
};

export default OnboardingFormSelect;
